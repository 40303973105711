.Invoices {
  margin-top: 75px !important;
}


.Invoices h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* .form-inline {
  width: 100%;
}

.form-group {
  width: 90%;
}

.input-group {
  width: 90% !important;
}

.form-control {
  width: 50% !important;
}

span.input-group-addon {
  width: 50px !important;
} */

@media only screen and (max-width: 576px) {

  .Invoices {
    margin-left: -1rem !important;
    width: 101vw !important;
  }
  .form-row-lines {
    margin-left: 0.1rem !important;
    /* margin-right: -100rem; */
    width: 99.5% !important;
  }
}

.react-pdf__Page__canvas {
  /* margin-left: 0rem !important; */
  width: 100% !important;
  /* max-width: 100vw ! important;
  min-height: 100vh ! important; */
  height: auto !important;
}