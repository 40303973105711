/* .Home {
} */

.Home .invoices h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Home .invoices p {
  color: #666;
}

/* .Home .listInvoices {
  margin-bottom: 0rem;
  margin-top: 0rem;
} */

@media only screen and (max-width: 576px) {

  .invoices {
    margin-left: -2rem !important;
    width: 101vw !important;
  }

  .listInvoices {
    margin-bottom: 1rem !important;
    /* margin-top: 10rem !important; */
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

