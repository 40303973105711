
.Clients h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 576px) {

  .Clients {
    margin-left: -1rem !important;
    width: 94vw !important;
  }

  .Clients .form {
    width: inherit;
  }

  .ClientInvoices {
    margin-left: -1rem !important;
    width: 101vw !important;
  }
}

.client-form-transition-enter,
.client-form-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0;
  /* transform: translateX(-100%); */

  transform: scale(0.9);
}

/* This is where we can add the transition*/
.client-form-transition-enter-active,
.client-form-transition-appear-active {
  opacity: 1;
  transform: translateX(0%);
  /* transition: opacity 0.2s,
  transform 0.2s; */
}

/* This fires as soon as the this.state.showList is false */
.client-form-transition-exit {
  opacity: 0;
  transform: scale(0.9);
}