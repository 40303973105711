/* .NewInvoice {
} */

/* .NewInvoice form {
  padding-bottom: 15px;
} */

.NewInvoice h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.NewInvoice form textarea {
  height: 300px;
  font-size: 24px;
}

.RemoveButton{
  margin-top: 2rem; 
}

@media only screen and (max-width: 576px) {

  .NewInvoice {
    margin-left: -1rem !important;
    width: 101vw !important;
  }

  .NewInvoice .form {
    margin-left: 0.1rem !important;
    /* margin-right: -100rem; */
    width: 99.5% !important;
  }
}
