.NewClients {
  margin-top: 75px !important;
}

.NewClients h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* .NewClients form {
  padding-bottom: 15px;
} */

/* .NewClients form textarea {
} */

.SearchButton {
  margin-top: 2rem;
}

@media only screen and (max-width: 576px) {

  .NewClients {
    margin-left: -1rem !important;
    width: 94vw !important;
  }

  .NewClients .form {
    width: inherit;
  }
}
