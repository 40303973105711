
.control-form-transition-enter,
.control-form-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0;
  /* transform: translateX(-100%); */

  transform: scale(0.9);
}

/* This is where we can add the transition*/
.control-form-transition-enter-active,
.control-form-transition-appear-active {
  opacity: 1;
  transform: translateX(0%);
  /* transition: opacity 0.2s,
  transform 0.2s; */
}

/* This fires as soon as the this.state.showList is false */
.control-form-transition-exit {
  opacity: 0;
  transform: scale(0.9);
}