/* body {
  font-family: sans-serif;
} */

/* .container {
  position: relative;
}

.display {
  position: relative;
  z-index: 1;
  width: 120px;
  height: 40px;
  background-color: #5a564c;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: background-color 350ms;
} */

/* .display:active {
  background-color: #000000;
}

.list-body { */
  /*position: absolute;*/
  /* top: 45px; */
  /* z-index: 1; */
  /* box-sizing: border-box; */
  /* width: 200px; */
  /* padding: 0 20px; */
  /* overflow: hidden; */
  /* background-color: #9e8949; */
  /* border-radius: 5px; */
/* } */

.list {
  padding: 0;
  list-style-type: none;
}

/* .list-item {
  padding: 5px 0;
} */

/* .list-item--active {
  color: blue;
  transition: color 1500ms;
} */

/* This fires as soon as the element enters the dorm */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0;
  /* transform: translateX(-100%); */

  transform: scale(0.9);
}

/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
  opacity: 1;
  transform: translateX(0%);
  /* transition: opacity 0.2s,
  transform 0.2s; */
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
  opacity: 0;
  transform: scale(0.9);
}

/* fires as element leaves the DOM*/
/* .list-transition-exit-active {
  
} */

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .form-row-lines {
    margin-left: 0.1rem !important;
    /* margin-right: unset !important; */
    width: 99.5% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .form-row-lines {
    margin-left: 0.1rem !important;
    /* margin-right: unset !important; */
    width: 99.5% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .form-row-lines {
    margin-left: 0.1rem !important;
    /* margin-right: unset !important; */
    width: 99.5% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .form-row-lines {
    margin-left: 0.1rem !important;
    /* margin-right: unset !important; */
    /* width: 115% !important; */
  }
}