/* .ClientsHome {  
} */

.ClientsHome .clients h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ClientsHome .clients p {
  color: #666;
}

@media only screen and (max-width: 576px) {

  .clients {
    margin-left: -2rem !important;
    width: 101vw !important;
  }

  .ListClients {
    margin-bottom: 1rem !important;
    /* margin-top: 10rem !important; */
  }

  .form-row-lines {
    margin-left: -1.2rem;
    margin-right: -100rem;
    /* width: 110%; */
  }
}