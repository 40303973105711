@media only screen and (max-width: 576px) {
  .App {
    margin-top: calc(env(safe-area-inset-top) + 4rem);
  }
}
@media only screen and (min-width: 576px) {
  .App {
    margin-top: 5rem !important;
  }
}
.App .navbar-brand {
  font-weight: bold;
}

@media only screen and (max-width: 576px) {
  .App .navbar {
    font-weight: bold;
    padding-top: env(safe-area-inset-top);
  }
}
@media only screen and (min-width: 576px) {
  .App .navbar {
    font-weight: bold;
    /* margin-bottom: 500px !important; */
  }
}
