.Settings h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.CheckBoxes {
  margin-top: 1.6rem;
}

@media only screen and (max-width: 576px) {

  .Settings {
    margin-left: -1rem !important;
    width: 94vw !important;
  }
  .Settings .form {
    width: inherit;
  }
}